
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class CardTitle extends Vue {

    @Prop(String) private readonly icon!: string

}
