
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class Dialog extends Vue {
        @Prop({default: 400, type: Number}) private maxWidth!: number
        @Prop() private readonly title!: string
        @Prop({default: null}) private readonly subTitle!: string
        @Prop({default: null}) private readonly content!: string
        @Prop({default: null, type: Function}) private readonly cancelAction!: () => void
        @Prop({default: null, type: Function}) private readonly confirmAction!: () => void

        @Prop({default: null}) private readonly cancelButtonText!: string
        @Prop({default: null}) private readonly confirmButtonText!: string

        @Prop({default: null}) private readonly cancelButtonIcon!: string
        @Prop({default: null}) private readonly confirmButtonIcon!: string

        @Prop() private display!: boolean
    }
