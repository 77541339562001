
    import {ConflictSegments} from '@/api/api'
    import {Component, Prop, Vue} from 'vue-property-decorator'


    interface TextSegment {
        name: string
        order: number
        criteria: Array<string>
    }

    interface TextConflict {
        name: string
        segments: Array<TextSegment>
    }

    @Component
    export default class MessageConflictDialog extends Vue {

        @Prop() private display!: boolean
        @Prop({default: null, type: Function}) private readonly confirmAction!: () => void

        private get conflicts(): Array<TextConflict> {
            return this.$modules.currentMessage.conflicts.map((conflict) => {
                const textConflict: TextConflict = {
                    name: conflict.messageName,
                    segments: this.getSegmentsById(conflict.segments),
                }
                return textConflict
            })
        }

        private get isADefaultConflict(): boolean {
            if (this.conflicts.length === 1) {
                if (this.conflicts[0].segments.length === 0) {
                    return true
                }
            }
            return false
        }

        private getSegmentsById(segments: Array<ConflictSegments>): Array<TextSegment> {
            return segments.map((seg) => {
                    const segment = this.$modules.segments.getById(seg.segmentId)
                    const textSegment: TextSegment = {
                        order: segment.order,
                        name: segment.name,
                        criteria: segment.criteria
                            .filter((criterion) => seg.criteriaIds.includes(criterion.id))
                            .sort((a, b) => a.order - b.order)
                            .map((criterion) => criterion.name),
                    }
                    return textSegment
                },
            ).sort((a, b) => a.order - b.order)
        }

    }
