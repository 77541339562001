
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component
    export default class MessageContentEdit extends Vue {
        @Prop() private document!: Document

        private get content() {
            const current = this.$modules.currentMessage.draftAndCurrent
            if (current && current.content) {
                return current.content
            }
            return ''
        }

        private set content(content: string) {
            this.$modules.currentMessage.setDraftContent(content)
        }

        private setIsContained(isContained: boolean) {
            this.$emit('contained', isContained)
        }

    }
