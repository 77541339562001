
    import {MessageState} from '@/api/api'
    import {userHasRightToValidateMessage} from '@/store/actions/rightActions'
    import {SaveCancelDialogInterface} from '@/store/types'
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component
    export default class EditMessage extends Vue {

        @Prop(String) private readonly id!: string
        private displayConflict = false
        private isContained = true
        private dialogPreview = false
        private saveCancelDialog: SaveCancelDialogInterface | undefined = undefined
        private isUnique = true

        private get message() {
            return this.$modules.currentMessage.draftAndCurrent
        }

        private get document() {
            return this.$modules.documents.getById(this.message.documentId!)
        }

        private get name() {
            return this.message.name
        }

        private set name(name) {
            if (name != null) {
                this.$modules.currentMessage.setDraftName(name)
            }
        }

        private get documentName() {
            return this.document !== undefined ? this.document.name : ''
        }

        private mounted() {
            this.$modules.currentMessage.loadMessage(this.id)
            this.saveCancelDialog = this.$refs.saveCancelDialog as unknown as SaveCancelDialogInterface
        }


        private resetDraft() {
            this.$modules.currentMessage.resetDraft()
        }

        private get isDraftEmpty() {
            return this.$modules.currentMessage.isDraftEmpty
        }

        private get hasDraftChanged() {
            return this.$modules.currentMessage.hasDraftChanged
        }

        private get canSave() {
            return this.$modules.currentMessage.isDraftValid && this.isContained
        }

        private setIsContained(isContained: boolean) {
            this.isContained = isContained
        }

        private async save() {
            return await this.$modules.currentMessage.invalidateMessage().then(() => {
                this.isUnique = (this.$modules.currentMessage.status !== 409)
                if (!this.isUnique) {
                    this.saveCancelDialog!.interruptRedirection()
                }
            })
        }

        private async saveAndPush() {
            await this.save()
            if (this.isUnique) {
                this.saveCancelDialog!.forceRedirection()
                this.$router.push('/messages')
            }
        }

        private get hasRightToValidateMessage() {
            return userHasRightToValidateMessage(this.$modules, this.message)
        }

        private get displaySaveValidate() {
            return this.hasDraftChanged && this.hasRightToValidateMessage
        }

        private get displayValidate() {
            return !this.hasDraftChanged && this.hasRightToValidateMessage
        }

        private get canValidate() {
            const isValidated = this.message.state === MessageState.Validated
            return this.canSave && this.$modules.currentMessage.isMilestoneIdValid || !isValidated && this.isDraftEmpty
        }

        private hideConflict() {
            this.displayConflict = false
        }

        private async validate() {
            this.$modules.currentMessage.validateMessage().then(() => {
                this.isUnique = (this.$modules.currentMessage.status !== 409)
                if (this.isUnique) {
                    if (this.$modules.currentMessage.conflicts.length > 0) {
                        this.displayConflict = true
                    } else {
                        this.hideConflict()
                        this.saveCancelDialog!.forceRedirection()
                        this.$router.push('/messages')
                    }
                }
            })
        }

        private beforeRouteLeave(to: any, from: any, next: any) {
            this.saveCancelDialog!.popUp(to, next)
        }

        private setIsUnique(isUniqueTitle: boolean) {
            this.isUnique = isUniqueTitle
        }

        private get errorMessage(): string | undefined {
            if (!this.isUnique) {
                return 'edit-form.error-message-title-exists'
            }
        }
    }
