
    import {Document} from '@/api/api'
    import {CREATE_MESSAGE} from '@/store/Right'
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class MessageType extends Vue {

        @Prop(Boolean) private readonly isUnique!: boolean

        private get stepNumber(): number {
            return this.$modules.currentMessage.currentStep
        }

        private get documents(): Array<Document> {
            return this.$modules.documents.all.filter((document) => {
                return this.$modules.accounts.meHasRightOnDocument(document, CREATE_MESSAGE)
            })
        }

        get draftName(): string {
            return this.$modules.currentMessage.draftAndCurrent.name || ''
        }

        set draftName(value: string) {
            this.$modules.currentMessage.setDraftName(value)
        }

        get draftDocument(): Document | undefined {
            const documentId = this.$modules.currentMessage.draftAndCurrent.documentId
            if (documentId !== undefined) {
                return this.$modules.documents.getById(documentId)
            }
            return undefined
        }

        set draftDocument(document: Document | undefined) {
            if (document !== undefined) {
                this.$modules.currentMessage.setDraftDocumentId(document.id)
            }
        }



        private setIsUnique(isUniqueTitle: boolean) {
            this.$emit('unique', isUniqueTitle)
        }
    }
