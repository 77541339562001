
    import {MessageState} from '@/api/api'
    import {userHasRightToValidateMessage} from '@/store/actions/rightActions'
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {SaveCancelDialogInterface} from '@/store/types'

    @Component({})
    export default class CreateMessageConditions extends Vue {

        @Prop(String) private readonly id!: string
        private displayConflict = false
        private saveCancelDialog: SaveCancelDialogInterface | undefined = undefined

        private get message() {
            return this.$modules.currentMessage.current
        }

        private get messageDraft() {
            return this.$modules.currentMessage.draftAndCurrent
        }

        private get document() {
            return this.$modules.documents.getById(this.messageDraft.documentId!)
        }

        private get messageName() {
            return this.message !== undefined ? this.message.name : undefined
        }

        private get messageState() {
            return this.message !== undefined ? this.message.state : undefined
        }

        private get documents() {
            return this.$modules.documents.all
        }

        private get canSave() {
            return this.$modules.currentMessage.isDraftValid
        }

        private previous() {
            this.$router.push(`/create-message/${this.message!.id}/step-2-draft`)
        }

        private async save() {
            await this.$modules.currentMessage.saveStep()
        }

        private async saveAndPush() {
            await this.save()
            this.saveCancelDialog!.forceRedirection()
            this.$router.push('/messages')
        }

        // Step 3 validation

        private get isDraftEmpty() {
            return this.$modules.currentMessage.isDraftEmpty
        }

        private get hasRightToValidateMessage() {
            return userHasRightToValidateMessage(this.$modules, this.messageDraft)
        }

        private get displaySaveValidate() {
            return this.hasRightToValidateMessage &&
                (this.messageState === MessageState.Draft2 || this.messageState === MessageState.Draft3) &&
                !this.isDraftEmpty
        }

        private get displayValidate() {
            return this.hasRightToValidateMessage && this.messageState === MessageState.Draft3 && this.isDraftEmpty
        }

        private hideConflict() {
            this.displayConflict = false
        }

        private async validate() {
            this.$modules.currentMessage.validateMessage().then(() => {
                if (this.$modules.currentMessage.conflicts.length > 0) {
                    this.displayConflict = true
                } else {
                    this.hideConflict()
                    this.saveCancelDialog!.forceRedirection()
                    this.$router.push('/messages')
                }
            })
        }

        private mounted() {
            this.saveCancelDialog = this.$refs.saveCancelDialog as unknown as SaveCancelDialogInterface
        }

        private get canValidate() {
            // Can validate if:
            // - draft is valid and state is Draft2 or Draft3
            // - draft is empty and state is Draft3
            return this.messageState !== undefined &&
                (this.canSave
                    && this.$modules.currentMessage.isMilestoneIdValid
                    && (this.messageState === MessageState.Draft2 || this.messageState === MessageState.Draft3)
                    || this.isDraftEmpty && this.messageState === MessageState.Draft3)
        }

        private beforeRouteLeave(to: any, from: any, next: any) {
            this.saveCancelDialog!.popUp(to, next)
        }
    }
