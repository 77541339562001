
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

    @Component
    export default class EditFormMonthPicker extends Vue {
        @Prop(Date) private readonly value!: Date | null
        private menu = false
        private date: Date | null = null

        private parseDate() {
            if (this.date !== null) {
                const dateObj = new Date(this.date)
                this.$emit('input', dateObj)
                return dateObj.toLocaleString('default', {month: 'long'}) + ' ' + dateObj.getFullYear()
            }
            return null
        }


        @Watch('value')
        private reset() {
            if (this.value === null) {
                this.date = null
            }
        }
    }
