import {MilestoneState, Milestone, MilestoneCreate, MilestonePatch, MilestonesApi, ErrorDetails} from '@/api/api'
import {AxiosResponse, AxiosError} from 'axios'
import Vue from 'vue'
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules'

const milestonesApi: MilestonesApi = new MilestonesApi(undefined, process.env.VUE_APP_API_URL, undefined)

@Module
export default class MilestonesModule extends VuexModule {

    private milestones: { [key: string]: Milestone } = {}
    private httpStatus: number = 200

    get all(): Array<Milestone> {
        return Object.values(this.milestones)
    }

    get getById(): ((id: string) => Milestone | undefined) {
        return (id: string) => this.milestones[id]
    }

    get milestonesWithDocumentId(): ((documentId: string) => Array<Milestone>) {
        return (documentId: string) => {
            return this.all.filter((milestone) => milestone.documentId === documentId)
        }
    }

    get milestoneStateById(): (milestoneId: string) => MilestoneState | undefined {
        return (milestoneId: string) => {
            const milestone = this.getById(milestoneId)
            return milestone ? milestone.state : undefined
        }
    }

    @Mutation
    public reset() {
        this.milestones = {}
    }

    @Mutation
    public set(milestone: Milestone) {
        Vue.set(this.milestones, milestone.id, {
            ...milestone,
            modificationDate: new Date(milestone.modificationDate),
            latestExport: milestone.latestExport ? new Date(milestone.latestExport) : undefined,
        })
    }

    @Mutation
    public unset(milestoneId: string) {
        Vue.delete(this.milestones, milestoneId)
    }

    @Mutation
    public resetAll() {
        this.milestones = {}
    }

    @Action
    public setMilestones(milestones: Array<Milestone>) {
        this.resetAll()
        milestones.forEach((milestone: Milestone) => {
            this.set({
                ...milestone,
                modificationDate: new Date(milestone.modificationDate),
                latestExport: milestone.latestExport ? new Date(milestone.latestExport) : undefined,
            })
        })
    }

    /*
    Not implemented on the back.
    Uncomment when the back is ready.
    @Action
    public async loadMilestone(id: string) {
        const milestone = this.getById(id)
        if (milestone != null) {
            return milestone
        }
        return await milestonesApi.get(id)
            .then((response: AxiosResponse<Milestone>) => this.set(response.data))
    }
    */

    @Action
    public async loadMilestones() {
        await milestonesApi.list().then((response: AxiosResponse<Array<Milestone>>) => {
            this.setMilestones(response.data)
        })
    }

    @Action
    public async createMilestone(milestoneCreate: MilestoneCreate) {
        await milestonesApi.create(milestoneCreate).then((response: AxiosResponse<Milestone>) => {
            const milestone: Milestone = response.data
            this.set(milestone)
            this.setStatus(response.status)
        }).catch((error: AxiosError<ErrorDetails>) => {
            this.setStatus(error.response!!.status)
        })
    }

    @Action
    public async updateMilestone({milestoneId, milestonePatch}:
                                     { milestoneId: string, milestonePatch: MilestonePatch }) {
        await milestonesApi.update(milestoneId, milestonePatch).then((response: AxiosResponse<Milestone>) => {
            const milestone: Milestone = response.data
            this.set(milestone)
            this.setStatus(response.status)
        }).catch((error: AxiosError<ErrorDetails>) => {
            this.setStatus(error.response!!.status)
        })
    }

    @Action
    public async removeMilestone(milestoneId: string) {
        await milestonesApi.remove(milestoneId).then((_) => {
            this.unset(milestoneId)
        })
    }

    @Action
    public updateExportDate(milestoneId: string) {
        const milestone = this.getById(milestoneId)
        if (milestone) {
            // Update the export date with the current date.
            // It does not match exactly with the database state but it is close enough.
            milestone.latestExport = new Date()
            this.set(milestone)
        }
    }

    /**
     * permet de trier les campagnes entres elle
     *
     */
    public compareCampaing(a: Milestone, b: Milestone): number {
        // on compare sur l'etat pour fair passer les fini en bas de liste
        if (a.state !== b.state) {
            if (a.state === MilestoneState.Finished) {
                return 1
            }
            if (b.state === MilestoneState.Finished) {
                return -1
            }
        }
        // on tri sur les date de debut
        return a.dateStart.localeCompare(b.dateStart)
    }

    public milestoneStateFromDates(start: string, end: string): MilestoneState {
        const dateStart = new Date(start)
        const dateEnd = new Date(end)
        dateEnd.setHours(23, 59, 59, 999)
        const today: Date = new Date()
        if (today.getTime() < dateStart.getTime()) {
            return MilestoneState.NotStarted
        }
        if (today.getTime() > dateEnd.getTime()) {
            return MilestoneState.Finished
        }
        return MilestoneState.Running
    }

    @Mutation
    public setStatus(status: number) {
        this.httpStatus = status
    }

    get status() {
        return this.httpStatus
    }

}
