
import {Segment} from '@/api/api'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class EditFormRowSwitch extends Vue {
    @Prop(String) private readonly title!: string
    @Prop(Boolean) private readonly editable!: boolean
    @Prop(Boolean) private readonly value!: boolean
    @Prop(String) private readonly tooltip!: string


    get selected() {
        return this.value
    }

    set selected(selected: boolean) {
        this.$emit('input', selected)
    }

}
