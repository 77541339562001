
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {Criterion, Segment, SegmentSelectionEnum} from '@/api/api'

    @Component({})
    export default class EditFormSegmentSingle extends Vue {

        @Prop(Object) private readonly segment!: Segment
        @Prop(Number) private readonly index!: number
        @Prop(Boolean) private readonly disableInputs!: boolean
        @Prop({default: null}) private readonly selected!: Array<Criterion>

        private isSegmentMandatory(segment: Segment): boolean {
            return segment.selectionType === SegmentSelectionEnum.SingleValueMandatory
        }

        private get criterion(): Criterion | 0 {
            // In the case where the segment is 'single mandatory', we need the first criterion to be '0'.
            // This way, the radio button shows 'Aucun'.
            // This '0' value matches the ':value="0"' in the HTML radio declaration.
            if (this.selected.length === 0) {
                return 0
            }
            return this.selected[0]
        }

        private set criterion(criterion: Criterion | 0) {
            if (criterion === 0) {
                this.$emit('select', this.index, [])
            } else {
                this.$emit('select', this.index, [criterion])
            }
        }
    }
