
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class EditFormRowAutocomplete<T> extends Vue {
    @Prop(String) private readonly title!: string
    @Prop(String) private readonly label!: string
    @Prop(Array) private readonly items!: Array<T>
    @Prop(Function) private readonly itemText!: string
    @Prop(Object) private readonly value!: T

    get selected(): T {
        return this.value
    }

    set selected(selected: T) {
        this.$emit('input', selected)
    }
}
