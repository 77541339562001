
    import {MessageState} from '@/api/api'
    import {userHasRightToCreateMessage, userHasRightToValidateMessage} from '@/store/actions/rightActions'
    import {SaveCancelDialogInterface} from '@/store/types'
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component
    export default class DuplicateMessage extends Vue {

        @Prop(String) private readonly id!: string
        private displayConflict = false
        private isContained = true
        private dialogPreview = false
        private saveCancelDialog: SaveCancelDialogInterface | undefined = undefined
        private isUnique = true

        private get original() {
            return this.$modules.currentMessage.original
        }

        private get message() {
            return this.$modules.currentMessage.draftAndCurrent
        }

        private get document() {
            return this.$modules.documents.getById(this.message.documentId!)
        }

        private get name() {
            return this.message.name
        }

        private set name(name) {
            if (name != null) {
                this.$modules.currentMessage.setDraftName(name)
            }
        }

        private get documentName() {
            return this.document !== undefined ? this.document.name : ''
        }


        private resetDraft() {
            if (this.original !== undefined) {
                this.$modules.currentMessage.duplicateMessage(this.original)
            }

        }

        private get draftHasChanged(): boolean {
            if (this.original === undefined
                || this.message.name === this.original.name + ' (' + this.$modules.currentMessage.duplicateTimestamp + ')'
                && this.message.isDefault === this.original.isDefault
                && this.message.documentId === this.original.documentId
                && this.message.content === this.original.content
                && this.message.milestoneId === undefined
                && this.message.criteriaIds === this.original.criteriaIds
            ) {
                return false
            }
            return true
        }

        private get isDraftEmpty() {
            return this.$modules.currentMessage.isDraftEmpty
        }

        private get canSave() {
            return this.$modules.currentMessage.isDraftValid && this.isContained
        }

        private setIsContained(isContained: boolean) {
            this.isContained = isContained
        }

        private async save() {
            return this.$modules.currentMessage.invalidateMessage().then(() => {
                this.isUnique = (this.$modules.currentMessage.status !== 409)
                if (!this.isUnique) {
                    this.saveCancelDialog!.interruptRedirection()
                }
            })
        }

        private async saveAndPush() {
            await this.save().then(() => {
                if (this.isUnique) {
                    this.saveCancelDialog!.forceRedirection()
                    this.$router.push('/messages')
                }
            })
        }

        private get hasRightToValidateMessage() {
            return userHasRightToValidateMessage(this.$modules, this.message)
        }

        private get hasRightToCreateMessage() {
            if (this.document) {
                return userHasRightToCreateMessage(this.$modules, this.document.id)
            }
            return false
        }

        private get displaySave() {
            return this.hasRightToCreateMessage
        }

        private get displaySaveValidate() {
            return this.hasRightToValidateMessage
        }

        private get canValidate() {
            const isValidated = this.message.state === MessageState.Validated
            return this.canSave || !isValidated && this.isDraftEmpty
        }

        private async hideConflict() {
            this.displayConflict = false
            await this.save().then(() => {
                if (this.isUnique) {
                    this.saveCancelDialog!.forceRedirection()
                    const message = this.$modules.currentMessage.current!
                    this.$router.push(`/messages/${message.id}`)
                }
            })
        }

        private async validate() {
            await this.$modules.currentMessage.validateMessage().then(() => {
                this.isUnique = (this.$modules.currentMessage.status !== 409)
                if (this.isUnique) {
                    if (this.$modules.currentMessage.conflicts.length > 0) {
                        this.displayConflict = true
                    } else {
                        this.saveCancelDialog!.forceRedirection()
                        this.$router.push(`/messages`)
                    }
                }
            })
        }

        private mounted() {
            this.saveCancelDialog = this.$refs.saveCancelDialog as unknown as SaveCancelDialogInterface
        }

        private beforeRouteLeave(to: any, from: any, next: any) {
            this.saveCancelDialog!.popUp(to, next)
        }

        private setIsUnique(isUniqueTitle: boolean) {
            this.isUnique = isUniqueTitle
        }

        private get errorMessage(): string | undefined {
            if (!this.isUnique) {
                return 'edit-form.error-message-title-exists'
            }
        }
    }
