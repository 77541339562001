import {
    Conflict,
    Criterion,
    ErrorDetails,
    Message,
    MessageCreate,
    MessageDuplicate,
    MessagePatch,
    MessagePatchResponse,
    MessagesApi,
    MessageState,
    SegmentSelectionEnum,
    MilestoneState,
} from '@/api/api'

import MilestonesModule from '@/store/modules/MilestonesModule'
import SegmentsModule from '@/store/modules/SegmentsModule'
import {MessageDraft} from '@/store/types'
import {AxiosError, AxiosResponse} from 'axios'
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules'

const messagesApi: MessagesApi = new MessagesApi(undefined, process.env.VUE_APP_API_URL, undefined)

@Module
export default class CurrentMessageModule extends VuexModule {

    /**
     * We set the properties of the object. Otherwise Vue.JS will not track the
     * properties of `draft` and the values depending on it will not be
     * re-calculated.
     * Read for further infos : https://vuejs.org/v2/guide/reactivity.html
     *
     * The content of the message is not managed with the draft property.
     * We use the field `messageDraftContent` defined below.
     */

    public draft: MessageDraft = {
        name: undefined,
        documentId: undefined,
        content: undefined,
        milestoneId: undefined,
        criteriaIds: undefined,
        isDefault: undefined,
    }

    public conflicts: Array<Conflict> = []

    public step: number = 0
    public duplicateTimestamp: string | undefined = undefined

    private steps: Array<MessageState> = Object.values(MessageState)
    private milestonesModule: MilestonesModule
    private segmentsModule: SegmentsModule
    private currentMessage: Message | undefined = undefined
    private originalMessage: Message | undefined = undefined
    private httpStatus: number = 200

    get original(): Message | undefined {
        return this.originalMessage
    }

    constructor(options: any) {
        super(options)
        this.milestonesModule = options.milestonesModule
        this.segmentsModule = options.segmentsModule
    }

    get current(): Message | undefined {
        return this.currentMessage
    }


    get status(): number {
        return this.httpStatus
    }

    get currentStep(): number {
        if (this.currentMessage === undefined || this.currentMessage.state === undefined) {
            return 0
        }
        return this.stateToNumber(this.currentMessage.state)
    }

    get draftAndCurrent(): MessageDraft {
        let current: Message | undefined
        if (this.currentMessage !== undefined) {
            current = this.currentMessage
        } else {
            current = this.originalMessage
        }
        const draft = this.draft
        if (current === undefined) {
            return {
                name: draft.name,
                documentId: draft.documentId,
                content: draft.content ? draft.content : undefined,
                milestoneId: draft.milestoneId ? draft.milestoneId : undefined,
                criteriaIds: draft.criteriaIds ? draft.criteriaIds : undefined,
                isDefault: draft.isDefault ? draft.isDefault : undefined,
            }
        }
        return {
            name: draft.name !== undefined ? draft.name : current.name,
            documentId: draft.documentId !== undefined ? draft.documentId : current.documentId,
            content: draft.content !== undefined ? draft.content : current.content,
            milestoneId: draft.milestoneId !== undefined ?
                draft.milestoneId : this.currentMessage !== undefined ? current.milestoneId : undefined,
            criteriaIds: draft.criteriaIds !== undefined ? draft.criteriaIds : current.criteriaIds,
            isDefault: draft.isDefault !== undefined ? draft.isDefault : current.isDefault,
            state: current.state,
        }
    }

    /**
     * For the name to be valid, it must :
     * - have a lenght > 0 chars
     * - not be only white space
     * - have a length < 35 chars (TODO : parametrable)
     * We dont check if the name is unique at that point. It done on save by the
     * back.
     */
    public get isNameValid(): boolean {
        const name = this.draftAndCurrent.name
        return name !== undefined && name.trim().length > 0 && name.trim().length <= 35
    }

    // TODO : check if the id is in the document store.
    public get isDocumentIdValid(): boolean {
        return this.draftAndCurrent.documentId !== undefined
    }

    public get isContentValid(): boolean {
        const content = this.draftAndCurrent.content
        return ((content != null) && (this.getTextContent(content).trim().length > 0))
    }

    public get isMilestoneIdValid(): boolean {
        const milestoneId = this.draftAndCurrent.milestoneId
        if (milestoneId == null) {
            return false
        }
        return this.milestonesModule.milestonesWithDocumentId(this.draftAndCurrent.documentId!)
            .some((milestone) => milestone.id === milestoneId)
    }

    public get isMilestoneChoiceValid(): boolean {
        const milestones = this.milestonesModule.milestonesWithDocumentId(this.draftAndCurrent.documentId!)

        // if no milestone available, save action is possible
        if (milestones.length === 0 || milestones.filter((milestone) =>
            milestone.state !== MilestoneState.Finished).length === 0) {
            return true
        } else {
            return this.isMilestoneIdValid
        }
    }

    public get areCriteraValid(): boolean {
        const criteriaIds = this.draftAndCurrent.criteriaIds
        const mandatorySegments = this.segmentsModule.segmentsWithDocumentId(this.draftAndCurrent.documentId!)
            .filter((segment) => segment.selectionType === SegmentSelectionEnum.SingleValueMandatory
                || segment.selectionType === SegmentSelectionEnum.MultipleValueMandatory)
        // When no segments are mandatory, the criteria list is always correct.
        if (mandatorySegments.length === 0) {
            return true
            // If at least one segment is mandatory and no criteria is selected, the list in not valid.
        } else if (criteriaIds == undefined) { // TODO : criteriaIds shouldnt be 'null'
            return false
        }
        // Check that for each mandatory segment, the criteria list contains a criterion matching the segment
        return mandatorySegments.every((segment) => segment.criteria.some((criterion) => criteriaIds
            .includes(criterion.id)))
    }

    public get isDefaultMessage(): boolean {
        if (this.draftAndCurrent.isDefault === undefined) {
            return false
        }
        return this.draftAndCurrent.isDefault
    }

    public get isDraftValid(): boolean {
        if (this.isDraftEmpty) {
            return false
        }
        switch (this.step) {
            case 0:
                return this.isNameValid && this.isDocumentIdValid
                    && this.isContentValid
                    && this.isMilestoneChoiceValid && (this.areCriteraValid || this.isDefaultMessage)
            case 1:
                return this.isNameValid && this.isDocumentIdValid
            case 2:
                return this.isContentValid
            case 3:
                return this.isMilestoneChoiceValid && (this.areCriteraValid || this.isDefaultMessage)
            default:
                return false
        }
    }

    public get isDraftEmpty() {
        let isEmpty = true
        if (this.current !== undefined) {
            isEmpty = (this.current.isDefault === this.draft.isDefault) || (this.draft.isDefault === undefined)
        }

        isEmpty = isEmpty && !(this.draft.name !== undefined
            || this.draft.documentId !== undefined
            || this.draft.content !== undefined
            || this.draft.milestoneId !== undefined
            || this.draft.criteriaIds !== undefined)

        return isEmpty
    }


    public get hasDraftChanged() {
        if (this.currentMessage === undefined) {
            return !this.isDraftEmpty
        }
        let actualCriteria: Array<string>
        if (this.currentMessage.criteriaIds != null) {
            actualCriteria = Object.assign([], this.currentMessage.criteriaIds).sort()
        } else {
            actualCriteria = []
        }

        return (this.draft.name !== undefined
            && this.draft.name !== this.currentMessage.name)
            || (this.draft.isDefault !== undefined
                && this.draft.isDefault !== this.currentMessage.isDefault)
            || (this.draft.documentId !== undefined
                && this.draft.documentId !== this.currentMessage.documentId)
            || (this.draft.content !== undefined
                && this.draft.content !== this.currentMessage.content)
            || (this.draft.milestoneId !== undefined
                && this.draft.milestoneId !== this.currentMessage.milestoneId)
            || (this.draft.criteriaIds !== undefined
                && JSON.stringify(Object.assign([], this.draft.criteriaIds).sort()) !== JSON.stringify(actualCriteria))
    }

    @Mutation
    public setStatus(value: number) {
        this.httpStatus = value
    }

    @Mutation
    public setStep(value: number) {
        if (![0, 1, 2, 3].includes(value)) {
            throw new Error('Unknown step ' + value)
        }
        this.step = value
    }

    @Mutation
    public setDraftName(name: string) {
        this.draft.name = name
    }

    @Mutation
    public setDraftDocumentId(documentId: string) {
        this.draft.documentId = documentId
    }

    @Mutation
    public setDraftContent(content: string) {
        this.draft.content = content
    }

    @Mutation
    public setIsDefault(isDefault: boolean) {
        this.draft.isDefault = isDefault
    }


    @Mutation
    public setDraftMilestoneId(milestoneId: string) {
        this.draft.milestoneId = milestoneId
    }

    @Mutation
    public setDraftCriteria(criteria: Array<Criterion>) {
        this.draft.criteriaIds = criteria.map((criterion) => criterion.id)
    }

    @Mutation
    public setDuplicateTimestamp(timestamp: string | undefined) {
        this.duplicateTimestamp = timestamp
    }

    @Mutation
    public resetDraft() {
        this.draft.name = undefined
        this.draft.documentId = undefined
        this.draft.isDefault = undefined
        this.draft.content = undefined
        this.draft.milestoneId = undefined
        this.draft.criteriaIds = undefined
    }

    @Mutation
    public duplicateMessage(message: Message) {
        this.originalMessage = message
        this.currentMessage = undefined
        this.duplicateTimestamp = new Date().toLocaleDateString()
        this.draft.name = message.name + ' (' + this.duplicateTimestamp + ')'
        this.draft.isDefault = message.isDefault
        this.draft.documentId = message.documentId
        this.draft.content = message.content
        this.draft.milestoneId = undefined
        this.draft.criteriaIds = message.criteriaIds
    }

    @Action
    public reset() {
        this.unloadCurrentMessage()
    }

    @Mutation
    public setCurrentMessage(message: Message) {
        this.currentMessage = {
            ...message,
            modificationDate: new Date(message.modificationDate), // hack pour forcer le type date
        }
        this.originalMessage = undefined
    }

    @Mutation
    public unsetCurrentMessage() {
        this.currentMessage = undefined
    }

    @Mutation
    public unsetOriginalMessage() {
        this.originalMessage = undefined
    }

    @Action
    public async loadMessage(id: string) {
        return await messagesApi.get(id)
            .then((response: AxiosResponse<Message>) => this.setCurrentMessage(response.data))

    }

    @Action
    public async loadOriginalMessage(messageId: string) {
        return await messagesApi.get(messageId)
            .then((response: AxiosResponse<Message>) => this.duplicateMessage(response.data))
    }

    @Action
    public unloadCurrentMessage() {
        this.unsetCurrentMessage()
        this.unsetOriginalMessage()
        this.setDuplicateTimestamp(undefined)
        this.setStep(1)
        this.resetDraft()
    }

    @Action
    public async createMessage() {
        if (this.step !== 1) {
            throw new Error('\'createMessage\' should not be called from a step other than step 1')
        }
        return messagesApi
            .create(this.draft as MessageCreate)
            .then((response: AxiosResponse<Message>) => {
                    this.setCurrentMessage(response.data)
                    this.resetDraft()
                    this.setStatus(response.status)
                },
            )
            .catch((err: AxiosError<ErrorDetails>) => {
                this.setStatus(err.response!!.status)
            })
    }


    @Action
    public async saveStep() {
        if (!this.isDraftValid) {
            throw new Error('The state of the draft is invalid.')
        }
        if (this.currentMessage === undefined) {
            await this.createMessage()
        } else {
            await this.updateMessage()
        }
    }

    @Action
    public async validateMessage() {
        await this.updateMessage(MessageState.Validated)
    }

    @Action
    public async invalidateMessage() {
        await this.updateMessage(MessageState.Draft3)
    }

    /**
     * Update the message by calling the server endpoint.
     * @param state Optional state of the message to be set. If not set, the state will be set according to the step.
     */

    private async updateMessage(state?: MessageState) {
        const toSave: MessagePatch = {...this.draft, state: state ? state : this.stateToUpdate()}
        if (this.currentMessage === undefined) {
            return messagesApi.duplicate(toSave as MessageDuplicate).then(
                (response: AxiosResponse<MessagePatchResponse>) => {
                    this.setCurrentMessage(response.data.message)
                    this.resetDraft()
                    if (response.data.conflicts) {
                        this.setConflicts(response.data.conflicts)
                    } else {
                        this.setConflicts([])
                    }
                    this.setStatus(response.status)
                })
                .catch((err: AxiosError<ErrorDetails>) => {
                    this.setStatus(err.response!!.status)
                })
        } else {

            return messagesApi
                .update(this.currentMessage.id!, toSave)
                .then((response: AxiosResponse<MessagePatchResponse>) => {

                    this.setCurrentMessage(response.data.message)
                    this.resetDraft()
                    if (response.data.conflicts) {
                        this.setConflicts(response.data.conflicts)
                    } else {
                        this.setConflicts([])
                    }
                    this.setStatus(response.status)
                })
                .catch((err: AxiosError<ErrorDetails>) => {
                    this.setStatus(err.response!!.status)
                })
        }
    }

    @Mutation
    private setConflicts(conflicts: Array<Conflict>) {
        this.conflicts = conflicts
    }

    private stateToNumber(state: MessageState): number {
        return this.steps.findIndex((step) => step === state) + 1
    }

    private numberToState(stateNumber: number): MessageState | undefined {
        if (stateNumber > this.steps.length) {
            return undefined
        }
        return this.steps[stateNumber - 1]
    }

    /**
     * Compute the future state of the message when doing an update.
     * If undefined, it means that the message does not require an update since the current is the one in database.
     */
    private stateToUpdate(): MessageState | undefined {
        const current = this.current
        // Only update the state if the step which is saved has never been done before.
        // TODO: the state should be updated when there will be a validation process
        if (current === undefined || current.state === undefined || this.stateToNumber(current.state) < this.step) {
            return this.numberToState(this.step)
        }
    }

    /**
     * Get the text only value of a delta representation.
     * Used for input validation (cant be empty string)
     * The code is copy-pasta from QuillJS :
     * https://github.com/quilljs/quill/blob/develop/core/editor.js#L147
     */
    private getTextContent(content: string): string {
        return JSON.parse(content).ops
            .filter((op: any) => typeof op.insert === 'string')
            .map((op: any) => op.insert)
            .join('')
    }

}
