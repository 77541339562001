
    import {Component, Vue} from 'vue-property-decorator'

    @Component({})
    export default class CreateMessageType extends Vue {
        private get workflows() {
            return []
        } // TODO : Remove that
        private get validators() {
            return []
        } // TODO : Remove that
    }
