
    import {fetchData, resetStore} from '@/store/actions/globalActions'
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class Login extends Vue {
        private password: string = ''
        private tErrorMessage: string = ''
        private locked: boolean = false

        private get username(): string {
            return this.$modules.accounts.username
        }

        private set username(username: string) {
            this.$modules.accounts.setUsername(username)
        }

        private mounted() {
            // Reset the token and store
            this.$modules.accounts.invalidateToken()
            resetStore(this.$modules)
        }

        private async handleLogin() {
            await this.$modules.accounts.loadToken(this.password).then(async () => {
               this.tErrorMessage = ''
               await fetchData(this.$modules).then(() => this.$router.push('/'))
            }).catch((error) => {
                const exception = error.response.data.message
                const errorCode = error.response.status
                if (exception === 'Invalid user/password'
                    || exception === 'The Account for \'' + this.username + '\' was not found.') {
                    this.tErrorMessage = 'errors.login.invalid'
                } else if (exception === 'Disabled account') {
                    this.tErrorMessage = 'errors.login.disabled'
                } else if (errorCode === 418) {
                    this.locked = true
                    this.tErrorMessage = 'errors.login.blocked'
                } else {
                    this.tErrorMessage = 'errors.login.default'
                }
            })
        }
    }
