
import {customCategories, customEmojis} from '@/plugins/v-emoji-picker'
import {VEmojiPicker} from 'v-emoji-picker'
import {Component, Vue, Prop, Watch} from 'vue-property-decorator'


@Component({
  methods: {
    customEmojis() {
      return customEmojis
    },
    customCategories() {
      return customCategories
    }
  },
  components: {VEmojiPicker},
})
export default class EditFormEmojiPicker extends Vue {

  private menu = false

  selectEmoji(emoji: any)  {
    this.menu = false
    this.$emit('emoji', emoji)

  }
}

