
  import { Component, Vue  } from 'vue-property-decorator'

  @Component( {} )
  export default class MessageContentStatic extends Vue {

    private get content() {
      const current = this.$modules.currentMessage.current
      if (current) {
        return current.content
      }
      return ''
    }

  }
