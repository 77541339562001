
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {Validate} from '@/api/api'


    @Component
    export default class CreatePassword extends Vue {

        @Prop(String) private readonly token!: string
        @Prop(String) private readonly passwordFieldMessage!: string
        @Prop(String) private readonly passwordConfirmationFieldMessage!: string
        @Prop(String) private readonly passwordSaveButtonMessage!: string
        @Prop(String) private readonly passwordTitleMessage!: string
        @Prop(String) private readonly passwordMessage1!: string
        @Prop(String) private readonly passwordMessage2!: string
        @Prop(String) private readonly passwordSaveButtonIcon!: string

        private password: string = ''
        private passwordConfirmation: string = ''
        private show1: boolean = false
        private show2: boolean = false
        private tErrorMessage: string = ''

        private get getTheHint() {
            if (this.notTheSamePassword) {
                return this.$t('password.password-error')
            }
            return undefined
        }

        private get notTheSamePassword() {
            const notMatch = this.password !== this.passwordConfirmation
            return notMatch
        }

        private get matchingPasswords() {
            const notEmpty = this.password && this.passwordConfirmation
            const match = this.password === this.passwordConfirmation
            return notEmpty && match
        }

        private async handleSave() {
            const validate: Validate = {newPassword: this.password}
            await this.$modules.accounts.validateAccount(validate)
                .then(async () => this.$router.push('/login'))
        }
    }
