
    import {Document, Font} from '@/api/api'
    import defaultOptions, {fontSizes, lineHeightConfig} from '@/ui/components/editor-parts/defaultOptions'
    import Quill from 'quill'
    import Delta from 'quill-delta'
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator'


    @Component
    export default class Editor extends Vue {
        // permet d'indiquer le type des ref a typescript (~ cast)
        public $refs!: {
            editorDiv: HTMLElement,
        }

        @Prop(String) private value!: string
        @Prop() private document!: Document

        private quillEditor!: Quill
        private isContained = false
        private defaultHeight = 1.2

        onSelectEmoji(dataEmoji: any) {
            const selection = this.quillEditor.getSelection(true) // get position of cursor (index of selection)
            this.quillEditor.insertText(selection!.index, dataEmoji.data, 'user') // insert text into the cursor position
        }

        private get defaultSize(): number | undefined {
            return this.fontFamilies![0].defaultSize
        }

        private get defaultName(): string | undefined {
            return this.fontFamilies![0].name
        }

        private get quillStyle() {
            if (this.isContained) {
                return 'height : ' + (this.document.frameHeight + 2) + 'px;'
                    + ' -webkit-box-shadow: none;'
                    + ' -moz-box-shadow: none;'
                    + ' box-shadow: none;'
                    + ' width: ' + (this.document.frameWidth + 2) + 'px;'
            } else {
                return 'height : ' + (this.document.frameHeight + 4) + 'px;'
                    + 'width: ' + (this.document.frameWidth + 4) + 'px;'
            }
        }

        private get fontSizes() {
            return fontSizes
        }

        private get lineHeights() {
            return lineHeightConfig.whitelist
        }

        private get fontFamilies(): Array<Font> {
            const fontsId = this.document.fontsId
            if (fontsId === undefined) {
                return []
            } else {
                return fontsId.map((id) => this.$modules.fonts.getFontById(id)!)
            }
        }

        private mounted() {
            const fontFamilies = this.fontFamilies.map((aFont) => aFont.name)
            const font = Quill.import('formats/font')
            font.whitelist = fontFamilies
            Quill.register(font, true)
            this.quillEditor = new Quill(this.$refs.editorDiv, defaultOptions)
            this.quillEditor.on('text-change', (delta, oldContents) => this.onInput(delta, oldContents))
            this.updateFonts()
            this.updateLineHeights()
            this.updateWidth()
            this.updateEditor()
        }

        @Watch('value')
        private updateEditor() {
            /*
              Here we cant use `quillEditor.setContents`, it resets the cursor to the
              beginning of the line ! That's annoying (and unusable :p).

              We use `quillEditor.updateContents` instead. This method preserves the
              cursor position. Unfortunately, it's an append only operation. We need
              therefore to delete all content from the text editor beforehand.

              `delete(Number.MAX_SAFE_INTEGER)` is clearly a hack : I have no idea on
              how to implement it better.
            */
            let delta = new Delta().delete(Number.MAX_SAFE_INTEGER)
            if (this.value !== '') {
                delta = delta.concat(JSON.parse(this.value))
            }
            // We call the update method with the modifier 'silent' to prevent the
            // 'text-change' callback from being fired. It caused the draft to be
            // re-populated.
            this.quillEditor.updateContents(delta, 'silent')
            // -24 is the top/bottom padding of the quill editor
            this.isContained = (this.quillEditor.root.scrollHeight - 4) <= this.document.frameHeight

            this.$emit('contained', this.isContained)

        }

        private onInput(addedContent: Delta, oldContents: Delta) {
            this.updateWidth()
            const content = JSON.stringify(this.quillEditor.getContents())
            this.$emit('input', content)
        }

        private updateWidth() {
            for (const item of document.getElementById('editor')!.firstElementChild!.getElementsByTagName('p')) {
                (item as HTMLElement).style.maxWidth = this.document.frameWidth + 'px'
            }
        }

        // creates font related CSS style for Quill Component
        private updateFonts() {
            for (const font of this.fontFamilies) {
                const styleFont = document.createElement('style')
                styleFont.innerText = '.ql-font-' + font.name + ' { font-family: \'' + font.name + '\'}'
                document.head.appendChild(styleFont)
            }
            const styleCont = document.createElement('style')
            styleCont.innerText = '.ql-container { font-size: ' +
                this.defaultSize + 'pt; font-family: ' + this.defaultName + ' }'
            document.head.appendChild(styleCont)
        }

        private updateLineHeights() {
            for (const item of document.getElementsByClassName('ql-editor')) {
                (item as HTMLElement).style.lineHeight = '1.2'
            }
        }
    }
