var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currentId === undefined)?_c('EditFormStepper',{attrs:{"steps":[
    '/create-message',
    '/create-message',
    '/create-message'
],"stepPtr":_vm.currentState}}):_c('EditFormStepper',{attrs:{"steps":[
    '/create-message/'+_vm.currentId+'/step-1-type',
    '/create-message/'+_vm.currentId+'/step-2-draft',
    '/create-message/'+_vm.currentId+'/step-3-conditions'
],"stepPtr":_vm.currentState}})
}
var staticRenderFns = []

export { render, staticRenderFns }