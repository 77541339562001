
    import {Document, MessageState, MessageStateFilter, Milestone, MilestoneState} from '@/api/api'
    import {tableFooter} from '@/plugins/i18n'
    import {
        userHasRightToDeleteMessage,
        userHasRightToDuplicateMessage,
        userHasRightToEditMessage,
    } from '@/store/actions/rightActions'
    import {CREATE_MESSAGE} from '@/store/Right'
    import {FormatedMessageState, FormatedMilestoneState} from '@/store/types'
    import {Component, Vue} from 'vue-property-decorator'

    interface FormatedMessage {
        id: string
        name: string
        document: string
        milestone: string
        state: MessageState
        modificationDate: Date,
        latestExport: Date | undefined,
        isDefault: boolean,
    }


    @Component
    export default class AllMessages extends Vue {


        private pagination = {
            rowsPerPage: 10,
        }
        private sortBy = []
        private sortDesc = []
        private displayDeleteMessage = false
        private idMessageToDelete: string | null = null
        private selectedDocuments: Array<Document> = this.$modules.messages.documentsFilter
        private selectedMilestoneStates: Array<FormatedMilestoneState> = this.$modules.messages.milestoneStatesFilter
        private selectedCampaigns: Array<Milestone> = this.$modules.messages.milestonesFilter
        private selectedMessageStates: Array<FormatedMessageState> = this.$modules.messages.statesFilter

        private get headers() {
            return [
                {text: '', value: 'default', sortable: false, width: '1%'},
                {text: this.$t('message.list.name'), align: 'left', value: 'name'},
                {text: this.$t('message.list.document'), align: 'left', value: 'document'},
                {text: this.$t('message.list.milestone'), align: 'left', value: 'milestone'},
                {text: this.$t('message.list.state'), align: 'left', value: 'state'},
                {text: '', value: 'actions', sortable: false},
            ]
        }

        private get footer() {
            return tableFooter((key: string) => this.$t(key))
        }

        private get messages(): Array<FormatedMessage> {
            return this.$modules.messages.all
                .filter((message) => this.getDocument(message.documentId) !== undefined)
                .map((message) => {
                    const formatedMessage: FormatedMessage = {
                        id: message.id,
                        name: message.name,
                        document: this.getDocument(message.documentId)!.name,
                        milestone: this.getMilestoneName(message.milestoneId),
                        state: message.state!!,
                        modificationDate: message.modificationDate,
                        latestExport: message.latestExport,
                        isDefault: message.isDefault ? message.isDefault : false,
                    }
                    return formatedMessage
                })
        }

        private get documents(): Array<Document> {
            return this.$modules.documents.all
        }

        private get milestoneStates(): Array<FormatedMilestoneState> {
            return Object.values(MilestoneState).map((state, index) => {
                return {
                    id: index,
                    name: this.milestoneStatesLabel(state),
                    state,
                }
            })
        }

        private get campaigns(): Array<Milestone> {
            this.selectedCampaigns = [] // effet de bord pour reset les selectioné & chaque changement de liste
            const documentIds = this.selectedDocuments.map((doc) => {
                return doc.id
            })
            const states = this.selectedMilestoneStates.map((state) => {
                return state.state
            })
            return this.$modules.milestones.all
                .filter((campaign) => {
                    if (documentIds.length === 0) {
                        return true
                    }
                    return documentIds.includes(campaign.documentId)
                })
                .filter((campaign) => {
                    if (this.selectedMilestoneStates.length === 0) {
                        return true
                    }
                    return states.includes(campaign.state)
                })
                .sort(this.$modules.milestones.compareCampaing)
        }

        private get messageStates(): Array<FormatedMessageState> {
            return Object.values(MessageStateFilter).map((state, index) => {
                return {
                    id: index,
                    name: this.messageStatesLabel(state),
                    state,
                }
            })
        }

        private get editionLink(): (message: FormatedMessage) => string {
            return (message: FormatedMessage) => {
                switch (message.state) {
                    case MessageState.Draft1:
                        return `create-message/${message.id}/step-2-draft`
                    case MessageState.Draft2:
                        return `create-message/${message.id}/step-3-conditions`
                    case MessageState.Draft3:
                    case MessageState.Validated:
                        return `/messages/${message.id}`
                    default:
                        throw Error('Wrong state')
                }
            }
        }

        private get canCreateMessage() {
            return this.$modules.accounts.meHasRightOnOneDocument(CREATE_MESSAGE)
        }

        private static compareItem(a: FormatedMessage, b: FormatedMessage, index: string, isDesc: boolean): number {
            const order = (!isDesc ? 1 : -1)
            switch (index) {
                case 'name':
                    return order * a.name.localeCompare(b.name)
                case 'document':
                    return order * a.document.localeCompare(b.document)
                case 'milestone':
                    return order * a.milestone.localeCompare(b.milestone)
                case 'state':
                    return order * a.state.toString().localeCompare(b.state.toString())
                default:
                    return 0
            }
        }

        private customSort(items: Array<FormatedMessage>, indexes: Array<string>, isDescs: Array<boolean>) {
            return items.sort((a, b) => {
                for (let cpt = 0; cpt < indexes.length; cpt++) {
                    const intermediate = AllMessages.compareItem(a, b, indexes[cpt], isDescs[cpt])
                    if (intermediate !== 0) {
                        return intermediate
                    }
                }
                // si pas de tri on utilise la date
                return b.modificationDate.getTime() - a.modificationDate.getTime()
            })
        }

        private refreshData() {

            this.$modules.messages.setDocumentsFilter(this.selectedDocuments)
            this.$modules.messages.setMilestoneStatesFilter(this.selectedMilestoneStates)
            this.$modules.messages.setMilestonesFilter(this.selectedCampaigns)
            this.$modules.messages.setStatesFilter(this.selectedMessageStates)

            this.$modules.messages.loadMessages()
        }

        private resetFilter() {
            this.selectedDocuments = []
            this.selectedMilestoneStates = []
            this.selectedCampaigns = []
            this.selectedMessageStates = []

            this.refreshData()
        }

        private getDocument(id: string): Document | undefined {
            return this.$modules.documents.getById(id)
        }

        private milestoneStatesLabel(state: MilestoneState): string {
            return this.$t('milestone.states.' + state) as string
        }

        private messageStatesLabel(state: MessageStateFilter): string {
            return this.$t('message.states.' + state) as string
        }

        private getMilestoneName(id: string | undefined): string {
            if (id === undefined) {
                return ''
            }
            const milestone = this.$modules.milestones.getById(id)
            if (milestone === undefined) {
                return ''
            }
            return milestone.name
        }

        private isInProduction(message: FormatedMessage): boolean {
            return message.latestExport !== undefined && message.latestExport > message.modificationDate
        }

        private redirectToEdit(message: FormatedMessage) {
            this.$router.push(this.editionLink(message))
        }

        private duplicate(id: string) {
            this.$router.push(`/messages/${id}/duplicate`)
        }

        private askRemove(id: string) {
            this.idMessageToDelete = id
            this.displayDeleteMessage = true
        }

        private remove() {
            this.displayDeleteMessage = false
            this.$modules.messages.remove(this.idMessageToDelete!)
        }

        private cancelRemove() {
            this.idMessageToDelete = null
            this.displayDeleteMessage = false
        }

        private hasRightToEditMessage(messageId: string): boolean {
            return userHasRightToEditMessage(this.$modules, messageId)
        }

        private hasRightToDeleteMessage(messageId: string): boolean {
            return userHasRightToDeleteMessage(this.$modules, messageId)
        }

        private hasRightToDuplicateMessage(messageId: string): boolean {
            return userHasRightToDuplicateMessage(this.$modules, messageId)
        }
    }
