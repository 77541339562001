
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component({})
    export default class EditFormNavigation extends Vue {

        @Prop({default: false}) private readonly activationMailBtn!: boolean
        @Prop({default: false}) private readonly cancel!: boolean
        @Prop({default: false}) private readonly previous!: boolean
        @Prop({default: false}) private readonly saveNext!: boolean
        @Prop({default: false}) private readonly next!: boolean
        @Prop({default: false}) private readonly nextAsk!: boolean
        @Prop({default: false}) private readonly save!: boolean
        @Prop({default: false}) private readonly saveValidate!: boolean
        @Prop({default: false}) private readonly validate!: boolean
        @Prop({default: false}) private readonly deleteBtn!: boolean
        @Prop({type: Boolean, default: false}) private readonly canCancel!: boolean
        @Prop(Boolean) private readonly canSave!: boolean
        @Prop({type: Boolean, default: false}) private readonly canValidate!: boolean
        @Prop({type: Boolean, default: false}) private readonly canNext!: boolean
        @Prop({type: Boolean, default: false}) private readonly canDelete!: boolean
        @Prop({type: String}) private readonly errorMessage!: string
    }
