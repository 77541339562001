import {
    SummariesApi,
    MessageSummary,
    MessageSummaryPage,
    MessagesApi,
    MessageState,
    MilestoneState, MessageStateFilter, Document, Milestone,
} from '@/api/api'
import {FormatedMessageState, FormatedMilestoneState} from '@/store/types'
import {AxiosResponse} from 'axios'
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules'

const messagesApi: MessagesApi = new MessagesApi(undefined, process.env.VUE_APP_API_URL, undefined)
const summariesApi: SummariesApi = new SummariesApi(undefined, process.env.VUE_APP_API_URL, undefined)

@Module
export default class MessagesModule extends VuexModule {

    public documentsFilter: Array<Document> = []
    public milestoneStatesFilter: Array<FormatedMilestoneState> = []
    public milestonesFilter: Array<Milestone> = []
    public statesFilter: Array<FormatedMessageState> = []

    private messageSummaries: { [key: string]: MessageSummary } = {}

    get all(): Array<MessageSummary> {
        return Object.values(this.messageSummaries)
    }

    get getById(): ((id: string) => MessageSummary | undefined) {
        return (id: string) => this.messageSummaries[id]
    }

    @Mutation
    public reset() {
        this.messageSummaries = {}
        this.documentsFilter = []
        this.milestoneStatesFilter = []
        this.milestonesFilter = []
        this.statesFilter = []
    }

    @Mutation
    public setDocumentsFilter(documents: Array<Document>) {
        this.documentsFilter = documents
    }

    @Mutation
    public setMilestoneStatesFilter(milestoneStates: Array<FormatedMilestoneState>) {
        this.milestoneStatesFilter = milestoneStates
    }

    @Mutation
    public setMilestonesFilter(milestones: Array<Milestone>) {
        this.milestonesFilter = milestones
    }

    @Mutation
    public setStatesFilter(states: Array<FormatedMessageState>) {
        this.statesFilter = states
    }


    @Mutation
    public setMessages(messages: Array<MessageSummary>) {
        this.messageSummaries = {}
        messages.forEach((message: MessageSummary) => {
            this.messageSummaries[message.id] = {
                ...message,
                modificationDate: new Date(message.modificationDate), // hack pour forcer le type date
                latestExport: message.latestExport ? new Date(message.latestExport) : undefined,
            }
        })
    }

    @Action
    public async loadMessages() {
        const documentIds = this.documentsFilter.map((doc) => {
            return doc.id
        })
        const milestoneStateIds = this.milestoneStatesFilter.map((state) => {
            return state.state
        })
        const milestoneIds = this.milestonesFilter.map((campaign) => {
            return campaign.id
        })
        const messageStateIds = this.statesFilter.map((state) => {
            return state.state
        })
        return summariesApi.list(documentIds, milestoneStateIds, milestoneIds, messageStateIds)
            .then((response: AxiosResponse<MessageSummaryPage>) => {
                this.setMessages(response.data.items)
            })
    }

    @Action
    public async remove(id: string) {
        return messagesApi.remove(id).then((_: AxiosResponse<void>) => {
            return this.loadMessages()
        })
    }

}
