
    import {Vue} from 'vue-property-decorator'

    export default class EditFormStepperMessage extends Vue {
        get currentId() {
            if (this.$modules.currentMessage.current) {
                return this.$modules.currentMessage.current.id
            } else {
                return undefined
            }
        }


        get currentState() {
            return this.$modules.currentMessage.currentStep
        }
    }
