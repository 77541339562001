
    import defaultOptions from '@/ui/components/editor-parts/defaultOptions'
    import Quill from 'quill'
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

    @Component
    export default class EditFormPreview extends Vue {

        public $refs!: {
            prevEditorDiv: HTMLElement,
        }

        @Prop({type: Boolean, default: false}) private readonly isZoomed!: boolean
        @Prop(Number) private readonly scale!: number


        private get message() {
            return this.$modules.currentMessage.draftAndCurrent
        }

        private get document() {
            const message = this.message
            return this.$modules.documents.getById(message!.documentId!)!
        }

        private get image() {
            return this.$modules.documents.currentDocumentImage
        }

        private docPrevHeight = 0
        private docPrevWidth = 0
        private txtPrevHeight = 0
        private txtPrevWidth = 0
        private txtMarginTop = 0
        private txtMarginLeft = 0
        private previewScale = 1.0
        private base64Image = ''

        private quillEditor!: Quill
        private hover = false

        private renderText() {
            if (this.$modules.currentMessage.draftAndCurrent.content != null) {
                const delta = JSON.parse(this.$modules.currentMessage.draftAndCurrent.content)
                this.quillEditor.setContents(delta, 'silent')
                this.updateLineHeights()
            }

        }

        @Watch('image')
        private update() {
            this.renderPreview()
            this.renderText()
        }

        @Watch('message')
        private requestTextUpdate() {
            this.renderText()
        }

        private renderPreview() {
            if (this.image !== undefined) {
                const divContainer = document.querySelector('#prev-container') as HTMLElement

                const divWidth = divContainer.offsetWidth
                const divHeight = divContainer.clientHeight

                const image = new window.Image()

                image.onload = () => {
                    if (this.$modules.documents.currentDocumentImage !== undefined) {

                        const proportion = divContainer.offsetWidth / image.width

                        this.docPrevWidth = image.width
                        this.docPrevHeight = image.height
                        this.previewScale = proportion

                        if (this.scale !== undefined) {
                            this.previewScale = this.scale
                        }

                        this.txtMarginTop = this.document.frameY
                        this.txtMarginLeft = this.document.frameX
                        this.txtPrevWidth = this.document.frameWidth
                        this.txtPrevHeight = this.document.frameHeight

                        this.base64Image = image.src
                    }
                }

                image.src = this.image.content

                this.$forceUpdate()
            }
        }

        private updateLineHeights() {
            for (const item of document.getElementsByClassName('ql-editor')) {
                (item as HTMLElement).style.lineHeight = '1.2'
            }
        }

        private mounted() {
            this.quillEditor = new Quill(this.$refs.prevEditorDiv, defaultOptions)
            this.quillEditor.disable()
            this.renderPreview()
            this.renderText()
            this.updateLineHeights()
        }
    }


